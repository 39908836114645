import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const TdbIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path fill="true" d="M 14.22,10.89
            C 14.22,10.89 14.22,22.03 14.22,22.03
            14.22,22.03 18.17,21.32 20.37,17.78
            22.58,14.24 22.11,10.89 22.11,10.89
            22.11,10.89 14.22,10.89 14.22,10.89 Z
            M 10.62,1.93
            C 10.62,1.93 10.62,9.39 10.62,9.39
            10.62,9.39 21.87,9.39 21.87,9.39
            21.87,9.39 21.24,6.20 17.89,3.67
            14.54,1.14 10.62,1.93 10.62,1.93 Z
            M 8.93,2.31
            C 8.93,2.31 2.12,4.21 1.74,11.19
            1.36,18.16 6.26,22.36 12.17,22.33
            12.23,22.36 12.22,14.97 12.22,14.97
            12.22,14.97 8.93,15.08 8.93,11.84
            8.93,6.94 8.93,2.31 8.93,2.31 Z"/>
        </SvgIcon>
    );
}

export default TdbIcon
